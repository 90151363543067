import { alternarOffcanvas } from "./utilidades/alternarOffcanvas";
import { listen as quicklinkListen } from "quicklink/dist/quicklink.mjs";
import { inicializarInternacionalizacao } from './localizacao/global/global';


const headerFooter = {
    alterarAbaHeader(abaSelecionada) {

        abaSelecionada = abaSelecionada.split('-')[0];
        if (abaSelecionada === 'vaga') {
            abaSelecionada = 'vagas';
        }

        for (const aba of document.getElementsByClassName('aba-logado')) {
            aba.classList.toggle('aba-logado-ativo', aba.classList.contains(abaSelecionada))
        }

        for (const abaMobile of document.getElementsByClassName('nav-mobile-item')) {
            abaMobile.classList.toggle('ativo', abaMobile.classList.contains(abaSelecionada))
        }
    },
    trocarFlecha: {
        _trocar(elemento) {
           
        },
        dropdown(dropdown) {
            dropdown.addEventListener('show.bs.dropdown', () => this._trocar(dropdown));
            dropdown.addEventListener('hide.bs.dropdown', () => this._trocar(dropdown));
        },
        collapse(botaoCollapse) {
            const alvo = document.getElementById(botaoCollapse.getAttribute('aria-controls'));
            alvo.addEventListener('show.bs.collapse', () => this._trocar(botaoCollapse));
            alvo.addEventListener('hide.bs.collapse', () => this._trocar(botaoCollapse));
        },
    },
    async menuLogado() {
        if (document.contains(document.querySelector("#header-empresa"))) {
            return;
        }
        const headerLogado = document.getElementById('header-candidato-logado');

        if (!document.contains(headerLogado)) {
            return;
        }

        const hotsiteTipo = document.getElementById('hotsiteTipo') == undefined ? '' : document.getElementById('hotsiteTipo').value;

        const { getGeoData } = await import('./utilidades/geoData');
        const { carregarUsuarioLogado } = await import('./utilidades/usuarioLogado');

        await carregarUsuarioLogado();

        const tagsBuscaEspecificaContainer = document.getElementById('tags-busca-especifica');
        if (tagsBuscaEspecificaContainer != undefined && (hotsiteTipo == '' || hotsiteTipo == 'Express')) {
            const geoData = getGeoData();

            if (geoData.existe) {
                tagsBuscaEspecificaContainer.classList.remove('d-none');              
                const i18next = await import('i18next');
                const classButton = hotsiteTipo == 'Express' ? 'btn-secondary' : 'btn-outline-primary';

                tagsBuscaEspecificaContainer.innerHTML = `
                    <a href="/${CULTURE_EMPREGARE}/vagas?query=&localidade=${encodeURIComponent(geoData.data.cidade)}" class="btn ${classButton} btn-sm">${i18next.t('GeolocalizacaoVagasEm') } ${geoData.data.cidade}</a>
                    <a href="/${CULTURE_EMPREGARE}/vagas?query=&localidade=${geoData.data.regiaoCodigo?.toLowerCase()}" class="btn ${classButton} btn-sm">${i18next.t('GeolocalizacaoVagasEstado') } ${geoData.data.regiaoCodigo}</a>
                `;
            }
        }

    },
    async init() {
        inicializarInternacionalizacao();
        const { autocompleteBuscaRecenteCargo, autocompleteBuscaRecenteLocalizacao } = await import('./utilidades/eventoBuscarVagaRedirect');

        quicklinkListen({
            ignores: [
                (uri, elem) => !elem.hasAttribute('prefetch') //Os links que possuiram tal atributo irão dar prefetch
            ]
        });

        const selectCultures = document.querySelectorAll('.cultura');
        const culturaSelecionada = document.getElementsByClassName('cultura-selecionada');

        for (const botaoCultura of culturaSelecionada) {
            botaoCultura.innerHTML = document.querySelector(`a.cultura[data-culture="${CULTURE_EMPREGARE}"]`).innerHTML
        }

        selectCultures.forEach(selectCulture =>
            selectCulture.onclick = e =>
            document.querySelectorAll('.cultura-selecionada').forEach(dropdown => {
                let culture = e.target.getAttribute('data-culture');

                if (!culture) 
                    culture = e.currentTarget.getAttribute('data-culture');

                window.location.href = window.location.href.replace(('/' + CULTURE_EMPREGARE), ('/' + culture));
            })
        );

    

        this.menuLogado();


        this.alterarAbaHeader(window.location.pathname.split('/').pop());
        alternarOffcanvas(document.getElementById('menu-lateral-logado'));

        const formHeaderBuscarVagas = document.getElementsByClassName('formHeaderBuscarVagas');

        for (const form of formHeaderBuscarVagas) {
            if (document.contains(form)) {
                //this.autocomplete(form, "#" + form.getAttribute('data-autocompleteId'));
                //this.autocompleteBuscasRecentes(form, 'input[name="cargo"]');
                autocompleteBuscaRecenteLocalizacao(form, "#" + form.getAttribute('data-autocompleteId'));
                autocompleteBuscaRecenteCargo(form, 'input[name="cargo"]');
            }            
        }

        const formHomeBuscarVaga = document.getElementById('formHomeBuscarVaga');

        if (document.contains(formHomeBuscarVaga)) {
            //headerFooter.autocomplete(formHomeBuscarVaga, '#localizacao-home');            
            //this.autocompleteBuscasRecentes(formHomeBuscarVaga, 'input[name="cargo"]');
            autocompleteBuscaRecenteLocalizacao(formHomeBuscarVaga, '#localizacao-home');
            autocompleteBuscaRecenteCargo(formHomeBuscarVaga, 'input[name="cargo"]');
        }

        const { login } = await import('./login');
        login();
    }
}

headerFooter.init();
